<template>
  <div>
    <Row class="task-amount">
      <i-col
        span="12"
        style="cursor:pointer;"
        :class="showAllTask?'text-orange':''"
        class="task-amount-border"
      >
        <p class="task-amount-title" @click="changeShowAllTask('all')">总计</p>
        <p
          class="task-amount-subtitle"
          @click="changeShowAllTask('all')"
        >{{taskCount?taskCount.totalTaskCount:0}}</p>
      </i-col>
      <i-col span="12" style="cursor:pointer;" :class="!showAllTask?'text-orange':''">
        <p class="task-amount-title" @click="changeShowAllTask('pendding')">待办</p>
        <p
          class="task-amount-subtitle"
          @click="changeShowAllTask('pendding')"
        >{{taskCount?taskCount.totalPendingTaskCount:0}}</p>
      </i-col>
    </Row>
    <Row class="p-t-20" :gutter="8">
      <i-col :xs="24" :sm="24" :md="24" :lg="20" class="m-b-5">
        <RadioGroup v-model="currentTypeValue" type="button"  size="small">
          <Radio v-for="item in taskTypes" :key="item.value" :label="item.value">{{item.name}}</Radio>
        </RadioGroup>
      </i-col>
    </Row>
    <component :is="currentComponent + 'Component'" />
  </div>
</template>

<script>
import { getTaskCountComplex } from '@/api/approval/approvaltask'
export default {
  components: {
    approvalComponent: (resolve) => {
      require(['./ApprovalTaskPage'], resolve)
    }
  },
  data () {
    return {
      showAllTask: false,
      taskTypes: [],
      currentTypeValue: null,
      taskCount: null,
      keyword: '',
      currentComponent: 'approval'
    }
  },
  created () {
    this.currentTypeValue = this.taskType ? this.taskType : -1
    this.showAllTask = (this.summaryType === 'all')
    this.initData()
  },
  methods: {
    // 初始化任务类型数量
    initData () {
      getTaskCountComplex({ approverUserId: this.userId }).then((res) => {
        if (res && !res.errcode) {
          this.taskCount = res
          this.$store.commit(
            'set_approvalTask_taskCount',
            res.taskItemCountList
          )
          this.taskTypes = res.taskItemCountList.map((item) => {
            return { value: item.taskType, name: item.taskTypeName }
          })
          this.taskTypes.unshift({ value: -1, name: '全部' })
        }
      })
    },
    // 根据当前的任务类型加载不同的列表页面
    initListComponent () {
      if (this.taskType === 1) {
        this.currentComponent = 'invoice'
      }
    },
    // 切换是否展示全部任务
    changeShowAllTask (summaryType) {
      this.showAllTask = (summaryType === 'all')
      this.$store.commit('set_approvalTask_summaryType', summaryType)
    }
  },

  computed: {
    beginUpdate () {
      return this.$store.state.approvalTask.beginUpdate
    },
    summaryType () {
      return this.$store.state.approvalTask.summaryType
    },
    taskType () {
      return this.$store.state.approvalTask.taskType
    },
    userId () {
      return this.$store.state.approvalTask.userId
    }
  },
  watch: {
    currentTypeValue (val) {
      this.$store.commit('set_approvalTask_taskType', val)
      this.$store.commit('set_approvalTask_pageNumber', 1)
    }
  }
}
</script>
